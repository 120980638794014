.footer {
    &__principal {
        @include padd(both, half);
        background: $darkestGray;

        &__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__text {
            color: $white;
            text-transform: uppercase;
            padding-bottom: 20px;
        }
    }

    &__secondary {
        padding: 15px 0;
        background: $black;
        text-align: center;

        a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: $white;
            text-decoration: none;

            img {
                margin-left: 5px;
            }
        }
    }
}
